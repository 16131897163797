import React from "react";
import { Link } from "react-router-dom";
import ScriptTag from "react-script-tag";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      img_name: [],
      scripts: [],
    };
  }

  componentDidMount() {
    fetch("https://api.darshsoftwares.com/get-logo")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            img_name: result.img_name,
            scripts: result.scripts,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    return (
      <div>
        <ScriptTag
          isHydrating={true}
          type="text/javascript"
          src="/jquery-1.12.4.min.js"
        />
        <ScriptTag
          isHydrating={true}
          type="text/javascript"
          src="/jquery.slicknav.min.js"
        />
        <ScriptTag
          isHydrating={true}
          type="text/javascript"
          src="/slicknav.js"
        />

        <header>
          <div className="header-area">
            <div className="main-header ">
              <div className="header-top d-none d-lg-block">
                <div className="container">
                  <div className="col-xl-12">
                    <div className="row d-flex justify-content-between align-items-center">
                      <div className="header-info-left">
                        <ul>
                          <li>
                            <i className="fas fa-phone"></i> +91 90091 21133{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="header-info-right">
                        <ul className="header-social">
                          <li>
                            <a href="https://facebook.com/DarshSoftwares">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/Darsh_Softwares/">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>

                          <li>
                            {" "}
                            <a href="https://g.page/darshsoftwares">
                              <i className="fab fa-google-plus-g"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-bottom  header-sticky">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xl-2 col-lg-2">
                      <div className="logo">
                        <a href="https://darshsoftwares.com">
                          <img
                            src={
                              this.state.base_url +
                              "webassets/" +
                              this.state.img_name
                            }
                            alt="logo Darsh Softwares"
                            height="80"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-10 col-lg-10">
                      <div className="menu-wrapper  d-flex align-items-center justify-content-end">
                        <div className="main-menu d-none d-lg-block">
                          <nav>
                            <ul id="navigation">
                              <li>
                                <Link to="/">Home</Link>
                              </li>
                              <li>
                                <Link to="/about">about</Link>
                              </li>
                              <li>
                                <a>Services</a>
                                <ul className="submenu">
                                  <li>
                                    <Link to="/service/web-design">
                                      Web Design & Development
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/service/seo">SEO</Link>
                                  </li>
                                  <li>
                                    <Link to="/service/graphic-design">
                                      Graphic Design
                                    </Link>
                                  </li>
                                  
                                </ul>
                              </li>
                              <li>
                                <Link to="/webdesign-ujjain-blog">Blog </Link>
                              </li>
                              <li>
                                <Link to="/contact-web-designer-ujjain">
                                  Contact
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mobile_menu d-block d-lg-none"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
export default Header;
