import React from "react";
import { Link } from "react-router-dom";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      img_name: [],
    };
  }

  componentDidMount() {
    fetch("https://api.darshsoftwares.com/get-logo")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            img_name: result.img_name,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const year = new Date().getFullYear();
    return (
      <footer>
        <div className="footer-area section-bg">
          <div className="container">
            <div className="footer-top footer-padding">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-logo">
                      <a href="/darshsoft_gcp/">
                        <img
                          src={
                            this.state.base_url +
                            "webassets/" +
                            this.state.img_name
                          }
                          alt="logo Darsh Softwares"
                          height="90"
                        />
                      </a>
                    </div>
                    <div className="footer-tittle">
                      <div className="footer-pera">
                        <p className="info1">
                          Darsh Softwares is a Web Design & Development
                          consulting and service provider that stands for
                          innovation, simplicity, and quality. We exist and
                          prosper only because of you. Your time and money are
                          valuable. Having its origin in early 2014, Darsh
                          Softwares is an IT firm headquartered in Ujjain.
                        </p>
                      </div>
                    </div>
                    <div className="footer-number"></div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Useful Links</h4>
                      <ul>
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/contact-web-designer-ujjain">Contact</Link>
                        </li>
                        <li>
                          <Link to="/terms-conditions">Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Contact Us</h4>
                      <div className="footer-pera">
                        <p className="info1">
                          121/2 Mahakal Road,
                          <br />
                          Ujjain (M.P),India <br />
                          <strong>Phone</strong> :+91 90091 21133
                          <br />
                          <strong>Email</strong>: info@darshsoftwares.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="col-xl-9 col-lg-8">
                  <div className="footer-copy-right">
                    <p>
                      Copyright &copy; {year + " "} Darsh Softwares All rights
                      reserved
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                  <div className="footer-social f-right">
                    <a href="https://facebook.com/DarshSoftwares">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://twitter.com/Darsh_Softwares/">
                      <i className="fab fa-twitter"></i>
                    </a>

                    <a href="https://g.page/darshsoftwares">
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
