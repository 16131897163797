import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
class BlogView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      seo_title: "",
      seo_description: "",
      blogs: [],
      recentposts: [],
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      errorMsg: "Something went wrong.",
      successMsg: "Thank you for contacting us.",
    };
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onChangeValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFormSubmit(e) {
    e.preventDefault();
    document.getElementById("message").innerHTML = "";
    document.getElementById("error").innerHTML = "";

    fetch("https://api.darshsoftwares.com/site/contact-form", {
      method: "post",
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status) {
          document.getElementById("message").innerHTML =
            '<div class="alert alert-success alert-dismissible fade show">' +
            this.state.successMsg +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span> </button>  </div>';
          document.getElementById("contact-form").reset();
          this.setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        } else {
          document.getElementById("error").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show">' +
            result.data.message +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span> </button>  </div>';
        }
      });
    // window.grecaptcha.ready(function () {
    // window.grecaptcha
    //  .execute("6Ld3KsgaAAAAAAbW6-gPmHjzdNJLRVF0WfiyXu3B", {
    // //   action: "submit",
    // })
    // .then(function () {
    //
    //   })
  }

  componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    fetch(
      "https://api.darshsoftwares.com/blog/" +
        nextProps.match.params.id +
        "/" +
        nextProps.match.params.slug
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            blogs: result.blogs,
            recentposts: result.recentposts,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  componentDidMount() {
    const elms = document.querySelectorAll("[data-background]");
    for (var i = 0; i < elms.length; i++) {
      elms[i].style.backgroundImage = "url(" + elms[i].dataset.background + ")";
    }
    fetch(
      "https://api.darshsoftwares.com/blog/" +
        this.props.match.params.id +
        "/" +
        this.props.match.params.slug
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            blogs: result.blogs,
            recentposts: result.recentposts,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    return (
      <section class="blog_area section-padding">
         
        <div class="container">
          <div class="row">
            <div class="col-lg-8 posts-list">
              {this.state.blogs.map((blog) => (
                
                <div class="single-post">
                  <Helmet>
          <title>{blog.post_title}</title>
          <meta name="description" content={blog.post_seo_desc} />
        </Helmet>
                  <div class="feature-img">
                    <img
                      class="img-fluid"
                      src={
                        this.state.base_url + "webassets/" + blog.feature_image
                      }
                      alt={blog.post_title}
                    />
                  </div>

                  <div class="blog_details">
                    <h2>{blog.post_title}</h2>
                    <ul class="blog-info-link mt-3 mb-4">
                      <li>
                       
                          <i class="fa fa-clock"></i>
                          {blog.date_added}
                      
                      </li>
                      <li>
                       
                          <i class="fa fa-user"></i>
                          {blog.post_category}
                     
                      </li>
                    </ul>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blog.post_body,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
            <div class="col-lg-4">
              <div class="blog_right_sidebar">
                <aside class="single_sidebar_widget popular_post_widget">
                  <h3 class="widget_title">Recent Posts</h3>
                  {this.state.recentposts.map((recentpost) => (
                    <div class="media post_item">
                      <div class="media-body">
                        <Link
                          to={
                            "/blog/" +
                            recentpost.id +
                            "/" +
                            recentpost.post_slug
                          }
                        >
                          <h3>{recentpost.post_title}</h3>
                        </Link>
                      </div>
                    </div>
                  ))}
                </aside>

                <aside class="single_sidebar_widget newsletter_widget">
                  <h4 class="widget_title">Quick Enquiry</h4>
                  <div id="message"></div>
                  <div id="error"></div>
                  <form
                    id="contact-form"
                    onSubmit={(e) => this.onFormSubmit(e)}
                  >
                    <div class="form-group">
                      <label class="control-label">Name</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        class="form-control"
                        onChange={this.onChangeValue}
                      ></input>
                    </div>
                    <div class="form-group">
                      <label class="control-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="name"
                        class="form-control"
                        onChange={this.onChangeValue}
                      ></input>
                    </div>
                    <div class="form-group">
                      <label class="control-label">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        class="form-control"
                        onChange={this.onChangeValue}
                      ></input>
                    </div>
                    <div class="form-group">
                      <label class="control-label">Subject</label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        class="form-control"
                        onChange={this.onChangeValue}
                      ></input>
                    </div>
                    <div class="form-group">
                      <label class="control-label">Message</label>
                      <textarea
                        name="message"
                        id="message"
                        class="form-control"
                        onChange={this.onChangeValue}
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <button
                        class="btn btn-primary"
                        name="contact-button"
                        data-action="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default BlogView;
