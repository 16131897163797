import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      seo_title: "",
      seo_description: "",
      blogs: [],
      recentposts: [],
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      errorMsg: "Something went wrong.",
      successMsg: "Thank you for contacting us.",
    };
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onChangeValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onFormSubmit(e) {
    e.preventDefault();
    document.getElementById("message").innerHTML = "";
    document.getElementById("error").innerHTML = "";
    fetch("https://api.darshsoftwares.com/site/contact-form", {
      method: "post",
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status) {
          document.getElementById("message").innerHTML =
            '<div class="alert alert-success alert-dismissible fade show">' +
            this.state.successMsg +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span> </button>  </div>';
          document.getElementById("contact-form").reset();
          this.setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        } else {
          document.getElementById("error").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show">' +
            result.data.message +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span> </button>  </div>';
        }
      });
    // window.grecaptcha.ready(function () {
    // window.grecaptcha
    //  .execute("6Ld3KsgaAAAAAAbW6-gPmHjzdNJLRVF0WfiyXu3B", {
    // //   action: "submit",
    // })
    // .then(function () {
    //
    //   })
  }
  componentDidMount() {
    <Pagination />;
    const elms = document.querySelectorAll("[data-background]");
    for (var i = 0; i < elms.length; i++) {
      elms[i].style.backgroundImage = "url(" + elms[i].dataset.background + ")";
    }
    fetch("https://api.darshsoftwares.com/webdesign-ujjain-blog")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            blogs: result.blogs,
            recentposts: result.recentposts,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    return (
      <section>
        <Helmet>
          <title>{this.state.seo_title}</title>
          <meta name="description" content={this.state.seo_description} />
        </Helmet>
        <div class="slider-area2">
          <div class="slider-height2 hero-overly2 d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="hero-cap hero-cap2 text-center">
                    <h1>Our Blog</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class="blog_area section-padding">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 mb-5 mb-lg-0">
                <div class="blog_left_sidebar">
                  <Pagination />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget popular_post_widget">
                    <h3 class="widget_title">Recent Posts</h3>
                    {this.state.recentposts.map((recentpost) => (
                      <div class="media post_item">
                        <div class="media-body">
                          <Link
                            to={
                              "/blog/" +
                              recentpost.id +
                              "/" +
                              recentpost.post_slug
                            }
                          >
                            <h3>{recentpost.post_title}</h3>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </aside>

                  <aside class="single_sidebar_widget newsletter_widget">
                    <h4 class="widget_title">Quick Enquiry</h4>
                    <div id="message"></div>
                    <div id="error"></div>
                    <form
                      id="contact-form"
                      onSubmit={(e) => this.onFormSubmit(e)}
                    >
                      <div class="form-group">
                        <label class="control-label">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          class="form-control"
                          onChange={this.onChangeValue}
                        ></input>
                      </div>
                      <div class="form-group">
                        <label class="control-label">Email</label>
                        <input
                          type="email"
                          name="email"
                          id="name"
                          class="form-control"
                          onChange={this.onChangeValue}
                        ></input>
                      </div>
                      <div class="form-group">
                        <label class="control-label">Phone</label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          class="form-control"
                          onChange={this.onChangeValue}
                        ></input>
                      </div>
                      <div class="form-group">
                        <label class="control-label">Subject</label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          class="form-control"
                          onChange={this.onChangeValue}
                        ></input>
                      </div>
                      <div class="form-group">
                        <label class="control-label">Message</label>
                        <textarea
                          name="message"
                          id="message"
                          class="form-control"
                          onChange={this.onChangeValue}
                        ></textarea>
                      </div>
                      <div class="form-group">
                        <button
                          class="btn btn-primary"
                          name="contact-button"
                          data-action="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Blog;
