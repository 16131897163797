import React from "react";
import { Helmet } from "react-helmet";
class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      seo_title: "",
      seo_description: "",
      content: [],
      html: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    fetch("https://api.darshsoftwares.com/service/" + nextProps.match.params.id)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            content: result.content,
            html: result.html,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  componentDidMount() {
    const elms = document.querySelectorAll("[data-background]");
    for (var i = 0; i < elms.length; i++) {
      elms[i].style.backgroundImage = "url(" + elms[i].dataset.background + ")";
    }

    fetch(
      "https://api.darshsoftwares.com/service/" + this.props.match.params.id
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            content: result.content,
            html: result.html,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    return (
      <section>
        <Helmet>
          <title>{this.state.seo_title}</title>
          <meta name="description" content={this.state.seo_description} />
        </Helmet>
        <div class="slider-area ">
          <div class="slider-active hero-banner">
            <div class="single-slider slider-height d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-8 col-lg-7 col-md-8 offset-lg-2">
                    <div class="hero__caption text-center">
                      <h1 data-animation="fadeInLeft" data-delay=".5s">
                        {this.state.content.service}
                      </h1>
                      <p data-animation="fadeInLeft" data-delay=".9s">
                        {this.state.content.description}
                      </p>

                      <div
                        class="hero__btn"
                        data-animation="fadeInLeft"
                        data-delay="1.1s"
                      >
                        <a
                          href="https://forms.gle/o8cdfXcVoEYEGxom6"
                          class="btn hero-btn"
                        >
                          SCHEDULE A CALL TODAY
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="support-company-area pt-30 pb-20 section-bg fix">
          <div className="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-tittle mb-70 text-center">
                  <h2> {this.state.content.service}</h2>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-xl-12 col-lg-12">
                <div class="right-caption ">
                  <div
                    class="support-caption"
                    dangerouslySetInnerHTML={{
                      __html: this.state.html,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Services;
