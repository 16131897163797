import React from "react";
import { Helmet } from "react-helmet";
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      seo_title: "",
      seo_description: "",
      contact_details: [],
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      errorMsg: "Something went wrong.",
      successMsg: "Thank you for contacting us.",
    };

    this.onChangeValue = this.onChangeValue.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onChangeValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFormSubmit(e) {
    e.preventDefault();
    document.getElementById("message").innerHTML = "";
    document.getElementById("error").innerHTML = "";
    fetch("https://api.darshsoftwares.com/site/contact-form", {
      method: "post",
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status) {
          document.getElementById("message").innerHTML =
            '<div class="alert alert-success alert-dismissible fade show">' +
            this.state.successMsg +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span> </button>  </div>';
          document.getElementById("contact-form").reset();
          this.setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        } else {
          document.getElementById("error").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show">' +
            result.data.message +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span> </button>  </div>';
        }
      });
    // window.grecaptcha.ready(function () {
    // window.grecaptcha
    //  .execute("6Ld3KsgaAAAAAAbW6-gPmHjzdNJLRVF0WfiyXu3B", {
    // //   action: "submit",
    // })
    // .then(function () {
    //
    //   })
  }
  componentDidMount() {
    const elms = document.querySelectorAll("[data-background]");
    for (var i = 0; i < elms.length; i++) {
      elms[i].style.backgroundImage = "url(" + elms[i].dataset.background + ")";
    }
    fetch("https://api.darshsoftwares.com/contact-web-designer-ujjain")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            contact_details: result.contact_details,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    return (
      <section>
        <Helmet>
          <title>{this.state.seo_title}</title>
          <meta name="description" content={this.state.seo_description} />
        </Helmet>
        <div class="slider-area2">
          <div class="slider-height2 hero-overly2 d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="hero-cap hero-cap2 text-center">
                    <h1>Contact</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="site-contact">
          <section class="contact-section">
            <div class="container">
              <div class="d-none d-sm-block mb-5 pb-4">
                <div
                  id="map"
                  style={{
                    height: "480px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      backgroundColor: "rgb(229, 227, 223)",
                    }}
                  >
                    <div
                      class="gm-style"
                      style={{
                        position: "absolute",
                        zIndex: "0",
                        left: "0px",
                        height: "100%",
                        width: "100%",
                        padding: "0px",
                        borderWidth: "0px",
                        margin: "0px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.contact_details.google_map,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2 class="contact-title">Get in Touch</h2>
                </div>
                <div class="col-lg-8">
                  <div id="message"></div>
                  <div id="error"></div>
                  <form
                    id="contact-form"
                    onSubmit={(e) => this.onFormSubmit(e)}
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label">Name</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            class="form-control"
                            onChange={this.onChangeValue}
                          ></input>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label">Email</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            class="form-control"
                            onChange={this.onChangeValue}
                          ></input>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label">Phone</label>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            class="form-control"
                            onChange={this.onChangeValue}
                          ></input>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label">Subject</label>
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            class="form-control"
                            onChange={this.onChangeValue}
                          ></input>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label class="control-label">Message</label>
                          <textarea
                            name="message"
                            id="message"
                            class="form-control"
                            onChange={this.onChangeValue}
                          ></textarea>
                        </div>
                      </div>

                      <div class="form-group">
                        <button
                          class="btn btn-primary"
                          name="contact-button"
                          data-action="submit"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-3 offset-lg-1">
                  <div class="media contact-info">
                    <span class="contact-info__icon">
                      <i class="ti-home"></i>
                    </span>
                    <div class="media-body">
                      <h3>{this.state.contact_details.address}</h3>
                    </div>
                  </div>
                  <div class="media contact-info">
                    <span class="contact-info__icon">
                      <i class="ti-tablet"></i>
                    </span>
                    <div class="media-body">
                      <h3>{this.state.contact_details.phone_no}</h3>
                    </div>
                  </div>
                  <div class="media contact-info">
                    <span class="contact-info__icon">
                      <i class="ti-email"></i>
                    </span>
                    <div class="media-body">
                      <h3>{this.state.contact_details.email}</h3>
                      <p>Send us your query anytime!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    );
  }
}
export default Contact;
