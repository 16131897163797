import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      seo_title: "",
      seo_description: "",
      site_about: "",
      Testimonials: [],
      Portfolios: [],
    };
  }

  componentDidMount() {
    const elms = document.querySelectorAll("[data-background]");
    for (var i = 0; i < elms.length; i++) {
      elms[i].style.backgroundImage = "url(" + elms[i].dataset.background + ")";
    }
    fetch("https://api.darshsoftwares.com/")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            site_about: result.site_about,
            Testimonials: result.Testimonials,
            Portfolios: result.Portfolios,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      autoplay: false,
      loop: true,
      arrows: true,
      prevArrow: (
        <button type="button" className="slick-prev">
          <i className="ti-angle-left"></i>
        </button>
      ),
      nextArrow: (
        <button type="button" className="slick-next">
          <i className="ti-angle-right"></i>
        </button>
      ),
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrow: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };

    return (
      <div>
        <Helmet>
          <title>{this.state.seo_title}</title>
          <meta name="description" content={this.state.seo_description} />
        </Helmet>
        <div className="slider-area ">
          <div className="slider-active hero-banner">
            <div className="single-slider slider-height d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-7 col-md-8 offset-lg-2">
                    <div className="hero__caption text-center">
                      <h1 data-animation="fadeInLeft" data-delay=".5s">
                        UJJAIN WEB DESIGN, SEO COMPANY
                      </h1>
                      <p data-animation="fadeInLeft" data-delay=".9s">
                        We offer a full range of in-house digital marketing
                        services
                      </p>

                      <div
                        className="hero__btn"
                        data-animation="fadeInLeft"
                        data-delay="1.1s"
                      >
                        <a
                          href="https://forms.gle/o8cdfXcVoEYEGxom6"
                          className="btn hero-btn"
                        >
                          SCHEDULE A CALL TODAY
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="support-company-area pt-30 pb-20 section-bg fix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-tittle mb-70 text-center">
                  <h2>About Us</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="right-caption text-center">
                  <div className="support-caption">
                    <p className="pera-top pb-20">{this.state.site_about}</p>

                    <Link to="/about" className="btn post-btn">
                      More About Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="categories-area pt-30 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-tittle mt-10 mb-70 text-center">
                  <h2>Our Digital Marketing Services</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-cat text-center">
                  <div className="cat-icon">
                    <span className="flaticon-desktop-monitor"></span>
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#">Web Design & Development </a>
                    </h5>
                    <p>We build conversion focused websites</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-cat text-center">
                  <div className="cat-icon">
                    <span className="flaticon-search"></span>
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#">SEO</a>
                    </h5>
                    <p>We help businesses to get more leads and calls</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-cat text-center">
                  <div className="cat-icon">
                    <span className="flaticon-wordpress-logo"></span>
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#">WordPress Maintenance & Hosting</a>
                    </h5>
                    <p>
                      We offer WordPress Website Maintenance & Hosting Services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="services-area pt-20 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-tittle mb-70 text-center">
                  <h2>Our Portfolio</h2>
                </div>
              </div>
            </div>

            <div className="row">
              {this.state.Portfolios.map((portfolio) => (
                <div className="col-lg-6 col-md-6 col-sm-10">
                  <div className="single-services mb-100">
                    <div className="services-img">
                      <img src={portfolio.img} alt={portfolio.title} />
                    </div>
                    <div className="services-caption">
                      <span>{portfolio.title}</span>
                      <p>
                        <a href="#">{portfolio.desc}</a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="testimonial-area testimonial-padding"
          data-background="theme/assets/img/gallery/section_bg04.jpg"
        >
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-10 col-lg-10 col-md-9">
                <div className="h1-testimonial-active">
                  <Slider {...settings}>
                    {this.state.Testimonials.map((testimonials) => (
                      <div
                        className="single-testimonial text-center"
                        key={testimonials.id}
                      >
                        <div className="testimonial-caption ">
                          <div className="testimonial-top-cap">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="67px"
                              height="49px"
                            >
                              <path
                                fillRule="evenodd"
                                fill="rgb(240, 78, 60)"
                                d="M57.053,48.209 L42.790,48.209 L52.299,29.242 L38.036,29.242 L38.036,0.790 L66.562,0.790 L66.562,29.242 L57.053,48.209 ZM4.755,48.209 L14.263,29.242 L0.000,29.242 L0.000,0.790 L28.527,0.790 L28.527,29.242 L19.018,48.209 L4.755,48.209 Z"
                              />
                            </svg>
                            <p>{testimonials.text} </p>
                          </div>

                          <div className="testimonial-founder d-flex align-items-center justify-content-center">
                            <div className="founder-img">
                              <img
                                src={
                                  this.state.base_url +
                                  "webassets/" +
                                  testimonials.image
                                }
                                alt="name"
                                className="testimonial-img"
                              />
                            </div>
                            <div className="founder-text">
                              <span>{testimonials.name}</span>
                              <p>{testimonials.company}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
