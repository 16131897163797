import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Blog from "./Blog";
import BlogView from "./BlogView";
import Tnc from "./Tnc";
import Privacypolicy from "./Privacypolicy";
import NotFound from "./NotFound";
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/about" component={About}></Route>
            <Route
              path="/service/:id"
              component={(props) => <Services {...props} />}
            />

            <Route exact path="/webdesign-ujjain-blog" component={Blog}></Route>
            <Route
              path="/blog/:id/:slug"
              component={(props) => <BlogView {...props} />}
            />
            <Route
              exact
              path="/contact-web-designer-ujjain"
              component={Contact}
            ></Route>
            <Route exact path="/terms-conditions" component={Tnc}></Route>
            <Route
              exact
              path="/privacy-policy"
              component={Privacypolicy}
            ></Route>

            <Route exact path="" component={NotFound}></Route>
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
