import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./App.css";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      base_url: "https://api.darshsoftwares.com/",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  receivedData() {
    axios
      .get(`https://api.darshsoftwares.com/webdesign-ujjain-blog`)
      .then((res) => {
        const data = res.data.blogs;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const postData = slice.map((blog) => (
          <React.Fragment>
            <article class="blog_item">
              <div class="blog_item_img">
                <Link to={"/blog/" + blog.id + "/" + blog.post_slug}>
                  <img
                    class="card-img rounded-0"
                    src={
                      this.state.base_url + "webassets/" + blog.feature_image
                    }
                    alt={blog.post_title}
                  />
                </Link>
              </div>

              <div class="blog_details">
                <Link
                  to={"/blog/" + blog.id + "/" + blog.post_slug}
                  class="d-inline-block"
                >
                  <h2>{blog.post_title}</h2>
                </Link>
                <div class="body-content">  <div dangerouslySetInnerHTML={{ __html: blog.post_body }}></div></div>
                <ul class="blog-info-link">
                  <li>
                    <a href="#">
                      <i class="fa fa-clock"></i>
                      {blog.date_added}
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i class="fa fa-user"></i>
                      {blog.post_category}
                    </a>
                  </li>
                </ul>
              </div>
            </article>
          </React.Fragment>
        ));

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),

          postData,
        });
      });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
  };

  componentDidMount() {
    this.receivedData();
  }
  render() {
    return (
      <div>
        {this.state.postData}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"page-item active"}
          activeLinkClassName={"page-link"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
      </div>
    );
  }
}
