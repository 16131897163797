import React from "react";
import { Helmet } from "react-helmet";
class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      base_url: "https://api.darshsoftwares.com/",
      seo_title: "",
      seo_description: "",
      content: [],
    };
  }

  componentDidMount() {
    const elms = document.querySelectorAll("[data-background]");
    for (var i = 0; i < elms.length; i++) {
      elms[i].style.backgroundImage = "url(" + elms[i].dataset.background + ")";
    }
    fetch("https://api.darshsoftwares.com/about")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            base_url: "https://api.darshsoftwares.com/",
            seo_title: result.seo_title,
            seo_description: result.seo_description,
            content: result.content,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    return (
      <section>
        <Helmet>
          <title>{this.state.seo_title}</title>
          <meta name="description" content={this.state.seo_description} />
        </Helmet>
        <div class="slider-area2">
          <div class="slider-height2 hero-overly2 d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="hero-cap hero-cap2 text-center">
                    <h1>About</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="site-about">
          <div class="container">
            <div class="row">
              <div class="about-details pt-30">
                <div class="container">
                  <div class="row">
                    <div
                      class="offset-xl-1 col-lg-8"
                      dangerouslySetInnerHTML={{
                        __html: this.state.content.page_content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
