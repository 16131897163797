import React from "react";
function NotFound() {
  return (
    <div
      class="site-error"
      style={{ paddingTop: "20px", paddingBottom: "20px" }}
    >
      <div class="col-md-9 offset-1">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Not Found (#404)</h4>
            <div class="alert alert-danger">Page not found. </div>

            <p>
              The above error occurred while the Web server was processing your
              request.
            </p>
            <p>
              Please contact us if you think this is a server error. Thank you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NotFound;
